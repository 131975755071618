import { render, staticRenderFns } from "./doubleBar.vue?vue&type=template&id=af12b48e&scoped=true&"
import script from "./doubleBar.vue?vue&type=script&lang=js&"
export * from "./doubleBar.vue?vue&type=script&lang=js&"
import style0 from "./doubleBar.vue?vue&type=style&index=0&id=af12b48e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af12b48e",
  null
  
)

export default component.exports